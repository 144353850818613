.root {
	composes: g-grid-container from global;
}

.inner {
	composes: g-grid from global;
}

.text {
	grid-column: 1 / -1;
	margin-bottom: var(--hdsplus-spacing-09);

	@media (--medium-up) {
		grid-column: 1 / 7;
		margin-bottom: var(--hdsplus-spacing-11);
	}

	@media (--large) {
		grid-column: 1 / span 5;
		margin-bottom: var(--hdsplus-spacing-12);
	}
}

.eyebrow {
	font-weight: 600;
	margin-bottom: var(--hdsplus-spacing-04);
}

.ctas {
	margin-top: var(--hdsplus-spacing-08);

	@media (--large) {
		margin-top: var(--hdsplus-spacing-09);
	}
}

.items {
	grid-column: 1 / -1;
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: grid;
	gap: var(--hdsplus-spacing-08);
	grid-template-columns: 1fr;

	@media (--medium-up) {
		grid-template-columns: 2fr 2fr;
	}

	@media (--large) {
		grid-template-columns: repeat(var(--cols), 1fr);
	}

	& :global(.hds-card__container) {
		height: 100%;
	}
}
